import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withCard from 'core/hocs/withCard';
import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import { Mobile, Desktop } from 'core/components/breakpoint';

import withBindProps from 'core/hocs/withBindProps';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import TitleCard from 'site/components/TitleCardL';
import ThinButton from 'site/components/ThinButton';
import TextGray from 'site/components/TextGray';
import { NegativeMobile } from 'site/components/Wrappers';

import { buildRubricUrl, cardDateFormat, imageVersionExists } from 'site/utils';
import mapTypes from './types';
import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'topic_type',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: {},
});

function Card1(props) {
  const {
    content,
    theme,
    type,
    isMobile,
    imageVersion,
    highPriorityImage,
  } = props;

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
      topic_type: topicType,
      published_at: published,
    },
  } = content;

  const {
    image: {
      versions: {
        [imageVersion]: preCover,
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      title: rubricTitle,
      slug: rubricSlug,
      root_slug: rubricRoot,
    },
  } = relationships(content);

  const {
    showTime,
    imageMaxWidth,
  } = mapTypes[type] || {};

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];
  const cover = imageVersionExists(preCover) ? preCover : originalCover;

  return (
    <div className={cx('card1', styles.card1, type === 1 && styles._type1)}>
      <style jsx>{`
        .${styles.image}
          :global(.mobile) &
            max-width ${imageMaxWidth}px
          &:after
            transition opacity ${theme.animations.hover}
      `}</style>
      {showTime && (
        <Desktop>
          <div className={styles.time}>
            <TextGray>{cardDateFormat(published)}</TextGray>
          </div>
        </Desktop>
      )}
      <Link to={link} type='secondary'>
        <NegativeMobile>
          <div className={styles.image}>
            <SmartImage
              src={cover}
              previewSrc={previewCover}
              maxWidth={imageMaxWidth}
              maxHeight={isMobile ? 600 : 300}
              minHeight={200}
              aspectRatio={320 / 200}
              position='top'
              {...highPriorityImage && { fetchPriority: 'high', instant: true }}
            />
            {Icon && (
              <div className={styles.icon}>
                <Icon height={50} width={50} />
              </div>
            )}
          </div>
        </NegativeMobile>
        <TitleCard>{listHeadline || headline}</TitleCard>
        {showTime && (
          <Mobile>
            <div className={styles.time}>
              <TextGray>{cardDateFormat(published)}</TextGray>
            </div>
          </Mobile>
        )}
      </Link>
      {rubricSlug && (
        <div className={styles.rubric}>
          <Link to={buildRubricUrl(rubricSlug, rubricRoot)}>
            <ThinButton>{rubricTitle}</ThinButton>
          </Link>
        </div>
      )}
    </div>
  );
}

Card1.defaultProps = {
  type: 0,
};

Card1.propTypes = {
  /** Переобложка изображения, которая будет использоваться в карточке вместо `original` */
  imageVersion: PropTypes.string,
  content: relationshipsPropTypes({
    image: imagePropTypes(),
    rubric: PropTypes.object,
  }),
  isMobile: PropTypes.bool,
  type: PropTypes.oneOf([0, 1, 2]),
  theme: PropTypes.object,
  /**
  * Использование fetchPriority для загрузки картинки
  */
  highPriorityImage: PropTypes.bool,
};

const Card = withCard(Card1);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export const Card1Type1 = withBindProps({ type: 1 })(Card);
export const Card1Type2 = withBindProps({ type: 2 })(Card);

export default Card;
