import PropTypes from 'prop-types';
import cx from 'classnames';

import withBreakpoint from 'core/hocs/withBreakpoint';
import Socializator from 'core/components/Socializator';
import CommentsCount from 'core/components/CommentsCount';
import Link from 'core/components/Link';
import CommentsIcon from 'site/icons/CommentsIcon';
import styles from './index.styl';

const SocialShare = ({
  xid,
  isMobile,
  isCommentsDisabled,
}) => {
  return (
    <div className={cx(styles.wrapper, isCommentsDisabled && styles.right)}>
      {!isCommentsDisabled && (
        <Link
          type='secondary'
          to={`${xid}/comments`}
          className={styles.discussionLink}
        >
          <CommentsIcon width={isMobile ? 35 : 65} height={isMobile ? 25 : 45} />
          {!isMobile && <span className={styles.discussionTitle}>Обсудить</span>}
          &nbsp;<CommentsCount xid={xid} disableIcon />
        </Link>
      )}
      <Socializator />
    </div>
  );
};

SocialShare.propTypes = {
  isMobile: PropTypes.bool,
  xid: PropTypes.string,
  isCommentsDisabled: PropTypes.bool,
};

export default withBreakpoint(SocialShare);
