import React from 'react';
import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Image from 'core/components/Image';

import withCard from 'core/hocs/withCard';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import TitleCard from 'site/components/TitleCardM';
import TextGray from 'site/components/TextGray';
import ThinButton from 'site/components/ThinButton';

import { cardDateFormat, buildRubricUrl } from 'site/utils';
import { MOBILE_MAX_WIDTH } from 'site/constants';

import mapTypes from './types';
import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'topic_type',
  'comments_count',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: {},
});

function Card2(props) {
  const {
    content,
    theme,
    type,
    isMobile,
    button,
    utm,
  } = props;

  const {
    showComments,
    showImage,
    showTime,
    showRubric = true,
    imageHeight,
    desktopImageMaxWidth = 270,
  } = mapTypes[type] || {};

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
      topic_type: topicType,
      comments_count: commentsCount,
      published_at: published,
    },
  } = content;

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      title: rubricTitle,
      slug: rubricSlug,
      root_slug: rubricRoot,
      root_title: rubricRootTitle,
    },
  } = relationships(content);

  const {
    icons: {
      Video,
      Photo,
      Comments,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];
  const to = utm ? link + utm : link;
  const imageMaxWidth = isMobile ? MOBILE_MAX_WIDTH : desktopImageMaxWidth;

  return (
    <div className='card2'>
      <style jsx>{`
        .card2
          :global(.mobile) &
            max-width ${MOBILE_MAX_WIDTH}px

        .${styles.image}
          background ${theme.colors.placeholder}
          &:after
            transition opacity ${theme.animations.hover}
      `}</style>
      <Link
        to={to}
        type='secondary'
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        {/**
        * Проверяем наличие обложки для топиков типа новость
        */}
        {showImage && (cover || remoteImage) && (
          <div className={styles.image}>
            <Image
              {...cover && { src: cover }}
              {...remoteImage && { url: remoteImage }}
              {...cover && { previewSrc: previewCover }}
              maxWidth={imageMaxWidth}
              {...imageHeight && { height: imageHeight }}
              aspectRatio={1.6}
              position='top'
            />
            {Icon && (
              <div className={styles.icon}>
                <Icon
                  height={50}
                  width={50}
                />
              </div>
            )}
          </div>
        )}
        <TitleCard>{listHeadline || headline}</TitleCard>
        {showTime && (
          <div className={styles.time}>
            <TextGray>{cardDateFormat(published)}</TextGray>
          </div>
        )}
        {showComments && (
          <div className={styles.social}>
            <Comments value={commentsCount} />
          </div>
        )}
        {button && (
          <div className={styles.button}>
            <Button>Пройти тест</Button>
          </div>
        )}
      </Link>
      {rubricRootTitle && (
        <div className={styles.rubric}>
          {rubricRootTitle && showRubric && (
            <Link to={buildRubricUrl(rubricSlug, rubricRoot)}>
              <ThinButton>{rubricTitle}</ThinButton>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

Card2.defaultProps = {
  type: 0,
};

Card2.propTypes = {
  content: relationshipsPropTypes({
    image: imagePropTypes(),
    rubric: PropTypes.object,
  }),
  isMobile: PropTypes.bool,
  type: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  button: PropTypes.string,
  theme: PropTypes.object,
  utm: PropTypes.string,
};

const Card = withCard(Card2);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;
