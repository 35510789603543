import React, { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import GlobalStyles from '../global';
import styles from './index.styl';

const NativeStyles = ({ theme }) => (
  <Fragment>
    <style global jsx>{`
      #begun_block_462307654
        .ad_native_wrapper
          border 1px solid ${theme.colors.active1}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function card2Styles(Component) {
  return withTheme(props => (
    <div className={cx(styles['adv-native_card-2'], 'adv-native adv-native_card-2')}>
      <GlobalStyles {...props} />
      <NativeStyles {...props} />

      <Component {...props} />
    </div>
  ));
}
