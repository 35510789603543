import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';


function TitleCardS(props) {
  const {
    theme,
    children,
  } = props;

  return (
    <div className='title'>
      {children}

      <style jsx>{`
        .title
          font 14px/1.3 ${theme.fonts.nuance}
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}
          :global(a):hover &
            color ${theme.colors.active1}
      `}
      </style>
    </div>
  );
}

TitleCardS.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(TitleCardS);
