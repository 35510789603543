import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import modelPropTypes, {
  readMoreAttributes,
} from 'core/utils/prop-types/model';
import withBindProps from 'core/hocs/withBindProps';

import readMore from 'core/components/TopicContent/blocks/readMore';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';

const Card2Type6 = withBindProps({ type: 6 })(Card2);
const Card3Type2 = withBindProps({ type: 2 })(Card3);
const Card3Type3 = withBindProps({ type: 3 })(Card3);

export default function ReadMore(props) {
  const {
    block,
    parentProps: {
      isMobile,
    },
  } = props;

  const ids = get(block, 'attributes.topic_ids', []);

  if (!ids.length) return null;

  const breakpointCard = isMobile ? Card3Type3 : Card3Type2;
  const breakpointDrumCard = isMobile ? Card3Type2 : Card2Type6;

  return (
    <div className='readMore'>
      {readMore(
        block,
        {
          title: 'Читайте также',
          singleCard: breakpointCard,
          feedCard: breakpointCard,
          drumCard: breakpointDrumCard,
        }
      )}
    </div>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  parentProps: PropTypes.object,
};
