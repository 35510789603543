import React from 'react';
import PropTypes from 'prop-types';
import color from 'core/libs/color';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import withCard from 'core/hocs/withCard';

import resolveRelationships from 'core/utils/relationships';

import TextGray from 'site/components/TextGray';

import styles from './index.styl';


const requiredPayloadImports = ['image', 'authors'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  authors: [],
});

function CardExpert(props) {
  const {
    content,
    theme,
  } = props;

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    authors: [author],
  } = relationships(content);

  const {
    name,
    job_title: jobTitle,
    avatar,
  } = author.attributes;

  const cover = avatar?.attributes?.versions?.original;
  const emptyAva = { rel_url: avatar, width: 698, height: 698 };

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.card}
          background-color ${color(theme.colors.active2).alpha(0.1).string()}
        .${styles.title}
          color ${theme.colors.primary}
          font 500 18px/1.4 ${theme.fonts.text}
      `}</style>
      <div className={styles.content}>
        <Link
          to={link}
          type='secondary'
        >
          <div className={styles.image}>
            <Image
              src={cover || emptyAva}
              width={160}
              aspectRatio={1}
            />
          </div>
          <div className={styles.title}>
            {listHeadline || headline}
          </div>
          <div className='name'><TextGray>{name}</TextGray></div>
          <div className='job'><TextGray>{jobTitle}</TextGray></div>
        </Link>
      </div>
    </div>
  );
}

CardExpert.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
};

const Card = withCard(CardExpert);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
