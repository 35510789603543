import PropTypes from 'prop-types';
import withBreakpoint from 'core/hocs/withBreakpoint';
import IndentWrap from './IndentWrap';

function NegativeMobile(props) {
  const {
    children,
    isMobile,
  } = props;

  const indent = isMobile ? -20 : undefined;

  return (
    <IndentWrap left={indent} right={indent}>
      {children}
    </IndentWrap>
  );
}

NegativeMobile.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(NegativeMobile);
