import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withBindProps from 'core/hocs/withBindProps';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';
import PageLoader from 'core/components/Loader/PageLoader';

import Feed from 'core/components/Feed';

import { IndentWrap } from 'site/components/Wrappers';
import BreakpointSwitcher from 'site/components/BreakpointSwitcher';

import { Card1Type1, Card1Type2 } from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';

import {
  SuperFooter,
  Context,
  Native1Rubric,
  Native2Rubric,
} from 'site/components/Ads/desktop';
import AdWrapper from 'core/components/Ad/AdWrapper';

import {
  Listing1,
  ListingSpecRubric,
  Listing2,
} from 'site/components/Ads/mobile';

const Card4Type1 = withBindProps({ type: 1 })(Card4);

function RubricTopics(props) {
  const {
    topics,
    isDesktop,
    notHero,
    isLoading,
  } = props;

  if (isLoading) return <PageLoader />;

  if (!topics?.length) return null;

  const first = topics.slice(0, 4);
  const second = topics.slice(4, 10);
  const third = topics.slice(10, 16);
  const fourth = topics.slice(16, topics.length);

  const heroCard = isDesktop ? Card1Type1 : Card1Type2;

  return (
    <Fragment>
      {first.length > 0 && (
        <Fragment>
          <Feed
            heroCard={notHero ? Card4Type1 : heroCard}
            card={Card4Type1}
            content={first}
            interitemSpacing={30}
            cardsReplacement={{
              2: withReplaceCardWithAd(Native1Rubric, isDesktop),
            }}
          />
          <AdWrapper top={30}>
            <BreakpointSwitcher
              desktop={<SuperFooter />}
              mobile={<Listing1 />}
            />
          </AdWrapper>
        </Fragment>
      )}
      {second.length > 0 && (
        <Fragment>
          <IndentWrap top={30} />
          <Feed
            card={Card4Type1}
            content={second}
            interitemSpacing={30}
            cardsReplacement={{
              4: withReplaceCardWithAd(Native2Rubric, isDesktop),
            }}
          />
          <AdWrapper top={30}>
            <BreakpointSwitcher
              desktop={<Context />}
              mobile={<ListingSpecRubric />}
            />
          </AdWrapper>
        </Fragment>
      )}
      {third.length > 0 && (
        <Fragment>
          <IndentWrap top={30} />
          <Feed
            card={Card4Type1}
            content={third}
            interitemSpacing={30}
          />
          <AdWrapper top={30}>
            <BreakpointSwitcher
              desktop={<Context count={2} />}
              mobile={<Listing2 />}
            />
          </AdWrapper>
        </Fragment>
      )}
      {fourth.length > 0 && (
        <Fragment>
          <IndentWrap top={30} />
          <Feed
            card={Card4Type1}
            content={fourth}
            interitemSpacing={30}
          />
        </Fragment>
      )}
      <IndentWrap bottom={40} />
    </Fragment>
  );
}

RubricTopics.propTypes = {
  topics: PropTypes.array,
  isDesktop: PropTypes.bool,
  notHero: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default withBreakpoint(RubricTopics);
