import deepmerge from 'core/libs/deepmerge';
import color from 'core/libs/color';

import withBindProps from 'core/hocs/withBindProps';

import fonts from './fonts';

import Card3 from 'site/cards/Card3';

import {
  VERTICAL_MOBILE,
  BLOCK_MOBILE_HORIZONTAL,
} from 'site/components/Indents';


const Card3Type1 = withBindProps({ type: 1 })(Card3);

export default function baseMobileTheme(atoms) {
  const texts = {
    title1: {
      font: `bold 30px/1.2 ${fonts.text}`,
    },
    title2: {
      font: `bold 20px/1.2 ${fonts.text}`,
    },
    title3: {
      font: `bold 18px/1.4 ${fonts.text}`,
    },
    title4: {
      font: `bold 16px/1.4 ${fonts.text}`,
    },
    title5: {
      font: `16px/1.4 ${fonts.text}`,
    },
    title6: {
      font: `16px/1.4 ${fonts.text}`,
    },

    headline: {
      font: `bold 24px/27px ${fonts.display}`,
    },

    body: {
      font: `16px/1.3 ${fonts.text}`,
    },

    lead: {
      font: `700 20px/26px ${fonts.text}`,
      marginTop: '29px',
    },

    incut: {
      font: `italic 22px/1.2 ${fonts.text}`,
    },
  };

  const commentsPreview = {
    headline: {
      font: `bold 12px/20px ${fonts.text}`,
    },
    name: {
      font: `bold 14px/18px ${fonts.text}`,
    },
  };

  const vikontReadMore = {
    block: {
      padding: '18px 0 18px 15px',
    },
    title: {
      letterSpacing: '0.08em',
      marginBottom: '16px',
      textAlign: 'center',
    },
    drum: {
      itemWidth: '90%',
    },
  };

  const vikontIncut = {
    padding: '0',
  };

  const scooter = {
    padding: '0 20px',
    logo: {
      width: 170,
      height: 46,
    },
    menuLink: {
      font: `bold 11px/30px ${fonts.text}`,
    },
    social: {
      margin: '0 0 0 15px',
      size: 30,
      iconSize: 14,
      borderRadius: 30,
    },
  };

  const shapka = {
    padding: '0 20px',
    background: atoms.colors.content,
    logo: {
      width: 145,
      height: 38,
    },
  };

  const expert = {
    jobTitle: {
      font: `400 12px/17px ${fonts.display}`,
    },
  };

  const topicFooter = {
    commentButton: {
      topSpacing: VERTICAL_MOBILE,
    },
    block: {
      interitemSpacing: 0,
      spacing: VERTICAL_MOBILE,
    },
  };

  const topicTopline = {
    font: `12px/1 ${fonts.text}`,
  };

  const errorPage = {
    imgWidth: 200,
    title: {
      font: `bold 60px/72px ${fonts.text}`,
    },
    subtitle: {
      font: `bold 24px/28px ${fonts.text}`,
    },
    card: Card3Type1,
  };

  const vikontImage = {
    maxWidth: 840,
  };

  const vikontOpinion = {
    opinion: {
      padding: '8px 0',
    },
    job: {
      font: `400 12px/20px ${fonts.text}`,
    },
    name: {
      font: `600 18px/27px ${fonts.text}`,
      marginBottom: '2.5px',
    },
    authorImage: {
      size: 56,
      marginRight: '20px',
    },
    body: {
      marginBottom: '23px',
      paddingLeft: '12px',
    },
    bodyBefore: {
      width: '2px',
    },
  };

  const pages = {
    notFound: errorPage,
    sentryFallback: {
      ...errorPage,
      card: null,
    },
  };

  const ads = {};

  const layout = {
    indents: {
      top: VERTICAL_MOBILE,
      right: BLOCK_MOBILE_HORIZONTAL,
      bottom: VERTICAL_MOBILE,
      left: BLOCK_MOBILE_HORIZONTAL,
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 16px/1.5 ${fonts.text}`,
      },
      info: {
        font: `500 14px/21px ${fonts.text}`,
      },
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `500 26px/32px ${fonts.display}`,
    },
    subtitle: {
      font: `400 italic 25px/32px ${fonts.display}`,
    },
    divider: {
      margin: '24px calc(100% - 300px) 24px 0',
      background: color(atoms.colors.primary).alpha(0.15).string(),
    },
  };

  const about = {
    headline: {
      font: texts.title1.font,
    },
    title2: {
      font: texts.title2.font,
    },
  };

  return deepmerge({
    controls: {
      vikontReadMore,
      scooter,
      shapka,
      expert,
      topicFooter,
      topicTopline,
      topicHeaderTexts,
      commentsPreview,
      vikontImage,
      vikontOpinion,
      vikontIncut,
      smartTeaser,
      about,
    },
    pages,
    ads,
    layout,
    texts,
  }, atoms);
}
