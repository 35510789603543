import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withCard from 'core/hocs/withCard';
import Link from 'core/components/Link';
import Image from 'core/components/Image';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import cutDown from 'core/utils/cut-down';
import resolveRelationships from 'core/utils/relationships';

import TextGray from 'site/components/TextGray';

import { cardDateFormat } from 'site/utils';
import { MOBILE_MAX_WIDTH } from 'site/constants';

import mapTypes from './types';
import styles from './index.styl';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'announce',
  'topic_type',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card4(props) {
  const {
    content,
    theme,
    type,
    isMobile,
  } = props;

  const {
    showTime,
  } = mapTypes[type] || {};

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
      announce,
      topic_type: topicType,
      published_at: published,
    },
  } = content;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];
  const imageMaxWidth = isMobile ? MOBILE_MAX_WIDTH : 840;

  return (
    <div className='card'>
      <style jsx>{`
        .headline
          color ${theme.colors.primary}
          font bold 20px/ 1.2 ${theme.fonts.text}
          transition color ${theme.animations.hover}
          .link:hover &
            color ${theme.colors.active1}

        .image
          background-color ${theme.colors.placeholder}
          :global(.mobile) &
            max-width ${MOBILE_MAX_WIDTH}px
          &:after
            transition opacity ${theme.animations.hover}

        .announce
          font 500 18px/1.2 ${theme.fonts.display}
          color ${theme.colors.primary}
      `}</style>
      <Link to={link} type='secondary'>
        <div className={styles.content}>
          {showTime && (
            <div className={styles.time}>
              <TextGray>{cardDateFormat(published)}</TextGray>
            </div>
          )}
          <div className={cx('image', styles.image)}>
            <Image
              src={cover}
              previewSrc={previewCover}
              maxWidth={imageMaxWidth}
              aspectRatio={1.6}
              position='top'
            />
            {Icon && (
              <div className={styles.icon}>
                <Icon height={40} width={40} />
              </div>
            )}
          </div>
          <div className={styles.text}>
            <div className={cx('headline', styles.headline)}>{listHeadline || headline}</div>
            {announce && <div className='announce'>{cutDown(announce)}</div>}
          </div>
        </div>
      </Link>
    </div>
  );
}

Card4.defaultProps = {
  type: 0,
};

Card4.propTypes = {
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  isMobile: PropTypes.bool,
  type: PropTypes.number,
  theme: PropTypes.object,
};

const Card = withCard(Card4);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;
