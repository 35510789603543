import PropTypes from 'prop-types';

import TopicFooter from 'core/components/TopicFooter';
import withTopic from 'core/hocs/withTopic';
import withTheme from 'core/hocs/withTheme';
import { TopicContentMaxWidth } from 'core/components/Wrappers';
import { IndentWrap } from 'site/components/Wrappers';
import { checkScrollToSiteInTopic } from 'core/components/ScrollToSite';

import Sponsored from 'site/components/Ads/Sponsored';
import TopicFooterBottom from './TopicFooterBottom';

function TopicFooterWrapper(props) {
  const {
    topic,
    theme: {
      controls: {
        topicFooter: {
          block: {
            spacing,
          },
        },
      },
    },
  } = props;

  const isScrollToSite = checkScrollToSiteInTopic(topic);

  return (
    <div style={{ clear: 'both' }}>
      <IndentWrap bottom={spacing} >
        <TopicContentMaxWidth>
          <Sponsored />
          <IndentWrap bottom={spacing} >
            <TopicFooter
              content={topic.content}
              hrefBuilder={slug => `/label/${slug}`}
            />
          </IndentWrap>
        </TopicContentMaxWidth>
        {!isScrollToSite && <TopicFooterBottom />}
      </IndentWrap>
    </div>
  );
}

TopicFooterWrapper.propTypes = {
  topic: PropTypes.shape({
    content: PropTypes.object,
  }),
  theme: PropTypes.object,
};

export default withTopic(withTheme(TopicFooterWrapper));
