import useRequest from 'core/hooks/useRequest';

import {
  authorQuery,
  topicsQuery,
} from 'core/queries/authorPage';

import withBindProps from 'core/hocs/withBindProps';
import PageLoader from 'core/components/Loader/PageLoader';
import AuthorPage from 'core/components/AuthorPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { filterRequiredParams } from 'core/utils/api';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import FooterAds from 'site/components/Ads/FooterAds';

import Card4 from 'site/cards/Card4';


const Card4Type1 = withBindProps({ type: 1 })(Card4);

const fields = filterRequiredParams([Card4Type1], 'fields');

function Author(props) {
  const results = useRequest({ queries: [
    authorQuery(props),
    topicsQuery({
      ...props,
      limit: 10,
      include: 'image',
      fields,
    }),
  ] });
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        {authorIsLoading && <PageLoader />}
        {!authorIsLoading &&
          <AuthorPage
            author={author}
            topics={topics}
            card={Card4Type1}
            interitemSpacing={30}
            isLoading={topicsAreLoading}
          />
        }
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds />
    </PageIndent>
  );
}

export default Author;
