import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withCard from 'core/hocs/withCard';
import Link from 'core/components/Link';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import TitleCard from 'site/components/TitleCardL';
import TitleCardS from 'site/components/TitleCardS';

import styles from './index.styl';

function CardPregnancy(props) {
  const {
    content,
    position,
    theme,
    breakpoint,
  } = props;

  const month = position + 1;

  const scoped = resolveScopedStyles((
    <scope>
      <style jsx>{`
        .link:hover:before
          background ${theme.colors.active2}
      `}</style>
    </scope>
  ));

  return (
    <Link
      to={content.attributes.link}
      type='secondary'
      className={cx(
        styles.card,
        styles[breakpoint],
        scoped.wrapClassNames('link')
      )}
    >
      <div className={styles.content}>
        <i className={cx(styles.icon, styles['icon_' + month])} />
        <div className={styles.title}>
          <TitleCard>{month}</TitleCard>
          <TitleCardS>месяц</TitleCardS>
        </div>
      </div>
      <scoped.styles />
    </Link>
  );
}

CardPregnancy.propTypes = {
  content: PropTypes.object,
  position: PropTypes.number,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

export default withCard(CardPregnancy);
