import PropTypes from 'prop-types';
import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';

function TitleCardL(props) {
  const {
    theme,
    children,
    tag,
  } = props;

  const Tag = tag;

  return (
    <Tag className={styles.title}>
      <style jsx>{`
        .${styles.title}
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}
          :global(a):hover &
            color ${theme.colors.active1}
      `}
      </style>
      {children}
    </Tag>
  );
}

TitleCardL.defaultProps = {
  tag: 'div',
};

TitleCardL.propTypes = {
  tag: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(TitleCardL);
