import PropTypes from 'prop-types';
import { Fragment } from 'react';

import withBindProps from 'core/hocs/withBindProps';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Feed from 'core/components/Feed';

import { IndentWrap } from 'site/components/Wrappers';
import Recommender from 'site/components/Recommender';
import Title5 from 'site/components/Title5';
import Popular from 'site/components/Popular';

import Card3 from 'site/cards/Card3';

const Card3Type1 = withBindProps({ type: 1 })(Card3);

const feedProps = {
  headerComponent: withBindProps({ line: true })(Title5),
  interitemSpacing: 20,
  columns: 2,
  grid: true,
  include: 'image',
};


function Boroda(props) {
  const {
    isMobile,
    showRecommender,
    showDiscussed,
    showPopular,
  } = props;

  const indent = isMobile ? 20 : 40;

  return (
    <Fragment>
      <IndentWrap bottom={indent} />
      {showRecommender && (
        <Fragment>
          <Recommender />
          <IndentWrap bottom={indent} />
        </Fragment>
      )}
      {showDiscussed && (
        <Fragment>
          <Feed
            {...feedProps}
            title='Обсуждаемое на сайте'
            card={Card3Type1}
            limit={4}
          />
          <IndentWrap bottom={indent} />
        </Fragment>
      )}
      {showPopular && (
        <Fragment>
          <Popular {...feedProps} />
          <IndentWrap bottom={indent} />
        </Fragment>
      )}
    </Fragment>
  );
}

Boroda.propTypes = {
  isMobile: PropTypes.bool,
  showRecommender: PropTypes.bool,
  showDiscussed: PropTypes.bool,
  showPopular: PropTypes.bool,
};

export default withBreakpoint(Boroda);
