import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';
import color from 'core/libs/color';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import withCard from 'core/hocs/withCard';
import resolveRelationships from 'core/utils/relationships';

import ThinButton from 'site/components/ThinButton';
import TextGray from 'site/components/TextGray';
import QuoteIcon from 'site/icons/Quote';
import avatar from 'site/icons/avatar.jpg';

import { buildRubricUrl } from 'site/utils';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'opinion', 'rubric'];

const requiredPayloadFields = [
  'link',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  opinion: {},
  rubric: {},
});

function CardMom(props) {
  const {
    content,
    theme,
  } = props;

  const {
    link,
  } = content.attributes;

  const {
    opinion: {
      author,
      body,
    },
    rubric: {
      title: rubricTitle,
      slug: rubricSlug,
      root_slug: rubricRoot,
    },
  } = relationships(content);

  const {
    name,
    job_title: jobTitle,
  } = author.attributes;

  const cover = get(author, 'attributes.avatar.attributes.versions.original');
  const emptyAva = { rel_url: avatar, width: 698, height: 698 };

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.card}
          background-color ${color(theme.colors.active1).alpha(0.1).string()}

        .${styles.body}
          color ${theme.colors.primary}
          font 500 italic 18px/1.4 ${theme.fonts.text}
      `}</style>
      <div className={styles.content}>
        <Link to={link} type='secondary'>
          <QuoteIcon width={21} height={16} />
          <div className={styles.body}>{body}</div>
          <div className={styles.image}>
            <Image
              src={cover || emptyAva}
              width={60}
              aspectRatio={1}
            />
          </div>
          <div className='author'>
            <div className='name'><TextGray>{name}</TextGray></div>
            <div className='job'><TextGray>{jobTitle}</TextGray></div>
          </div>
        </Link>
      </div>
      {rubricSlug && (
        <Link
          to={buildRubricUrl(rubricSlug, rubricRoot)}
          className={styles.rubric}
        >
          <ThinButton>{rubricTitle}</ThinButton>
        </Link>
      )}
    </div>
  );
}

CardMom.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
};

const Card = withCard(CardMom);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
