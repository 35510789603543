import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import withCard from 'core/hocs/withCard';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import TitleCardM from 'site/components/TitleCardM';
import TitleCardS from 'site/components/TitleCardS';

import { MOBILE_MAX_WIDTH } from 'site/constants';

import mapTypes from './types';
import styles from './index.styl';

const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'topic_type',
  'comments_count',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card3(props) {
  const {
    content,
    theme,
    type,
    isMobile,
  } = props;

  const {
    showComments,
    aspectRatio,
    desktopImageMaxWidth = 180,
    smallTitle,
    withBackground,
    rowReverse,
  } = mapTypes[type] || {};

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
      topic_type: topicType,
      comments_count: commentsCount,
    },
  } = content;

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    icons: {
      Video,
      Photo,
      Comments,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];
  const CardTitle = smallTitle && isMobile ? TitleCardS : TitleCardM;
  const imageMaxWidth = isMobile ? MOBILE_MAX_WIDTH : desktopImageMaxWidth;

  return (
    <div className={cx(
      'card3',
      withBackground && styles._withBackground && '_withBackground',
      rowReverse && styles._rowReverse,
    )}
    >
      <style jsx>{`
        .content
          ._withBackground &
            background ${theme.colors.layout}
        .image
          &:after
            transition opacity ${theme.animations.hover}
      `}</style>
      <Link
        to={link}
        type='secondary'
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        <div className={cx('content', styles.content)}>
          {(cover || remoteImage) && (
            <div className={styles.image}>
              <Image
                src={cover}
                {...cover && { src: cover }}
                {...remoteImage && { url: remoteImage }}
                {...cover && { previewSrc: previewCover }}
                width={isMobile ? 100 : 180}
                maxWidth={imageMaxWidth}
                aspectRatio={aspectRatio || (180 / 100)}
                position='top'
              />
              {Icon && (
                <div className={styles.icon}>
                  <Icon height={20} width={20} />
                </div>
              )}
            </div>
          )}
          <div className={styles.text}>
            <CardTitle>
              {listHeadline || headline}
            </CardTitle>
            {showComments && (
              <div className={styles.social}>
                <Comments value={commentsCount} />
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

Card3.defaultProps = {
  type: 0,
};

Card3.propTypes = {
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  isMobile: PropTypes.bool,
  type: PropTypes.number,
  theme: PropTypes.object,
};

const Card = withCard(Card3);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;
