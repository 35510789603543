import PropTypes from 'prop-types';
import { Fragment } from 'react';

import TopicContent from 'core/components/TopicContent';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';
import { TopicContentMaxWidth } from 'core/components/Wrappers';

import resolveRelationships from 'core/utils/relationships';

import PublicationLink from 'site/widgets/PublicationLink';
import Incut from 'site/widgets/Incut';
import ReadMore from 'site/widgets/ReadMore';
import PhotoGallery from 'site/widgets/PhotoGallery';
import Markdown from 'site/widgets/Markdown';
import NumberSnipet from 'site/widgets/Number';
import KidsQuestions, { addVisitedKidsQuestion } from 'site/components/KidsQuestions';

import { KIDS_QUESTIONS_SLUG } from 'site/constants';

import {
  InRead,
  Native3,
  AdCenter,
} from 'site/components/Ads/desktop';

import {
  InPage,
  Content1,
  Content2,
  Content3,
  Content4,
  ContentSpec,
} from 'site/components/Ads/mobile';

import { getRealType } from 'site/utils';

const relationships = resolveRelationships(['content', 'rubric'], {});

const desktopAds = [
  {
    before: 200,
    after: 200,
    showOnlyOnce: true,
    renderer: AdCenter,
  },
  {
    before: 1200,
    after: 200,
    showOnlyOnce: true,
    renderer: InRead,
  },
  {
    before: 600,
    after: 400,
    renderer: Native3,
  },
  {
    before: 1200,
    after: 200,
    renderer: AdCenter,
  },
];

const mobileAds = [
  Content1,
  InPage,
  ContentSpec,
  Content2,
  Content3,
  Content4,
].map((renderer, index, ads) => ({
  before: 600,
  after: 200,
  showOnlyOnce: ads.length !== index + 1,
  renderer,
}));


function SiteTopicContent({ content, isMobile }) {
  const {
    id: topicId,
  } = content;

  const {
    content: {
      widgets,
    },
    rubric: {
      slug,
    },
  } = relationships(content);

  if (slug === KIDS_QUESTIONS_SLUG) {
    addVisitedKidsQuestion(topicId);
  }


  const contentWidgets = getRealType(content) === 'news-gallery'
    ? widgets.slice(1, widgets.length)
    : widgets;

  return (
    <Fragment>
      <TopicContent
        content={contentWidgets}
        interitemSpacing={isMobile ? 20 : 30}
        desktopAds={desktopAds}
        mobileAds={mobileAds}
        blocks={{
          publicationLink(block, parentProps) {
            return (
              <PublicationLink block={block} parentProps={parentProps} />
            );
          },
          incut(block, parentProps, ctx) {
            return (
              <Incut
                block={block}
                parentProps={parentProps}
                ctx={ctx}
              />
            );
          },
          number(block, parentProps) {
            return <NumberSnipet block={block} parentProps={parentProps} />;
          },
          readMore(block, parentProps) {
            return (
              <ReadMore block={block} parentProps={parentProps} />
            );
          },
          photoGallery(block, parentProps) {
            return <PhotoGallery block={block} parentProps={parentProps} />;
          },
          markdown(block, parentProps, ctx) {
            return (
              <Markdown
                block={block}
                parentProps={parentProps}
                ctx={ctx}
              />
            );
          },
        }}
      />

      {slug === KIDS_QUESTIONS_SLUG && (
        <TopicContentMaxWidth>
          <YandexMetrikaGoalSender
            show={isMobile ? 'child_questions_mobile_after_topic_show' : 'child_questions_desktop_after_topic_show'}
            click={isMobile ? 'child_questions_mobile_after_topic_click' : 'child_questions_desktop_after_topic_click'}
            skipTargetCheck
            checkScrollY
          >
            <KidsQuestions
              limit={isMobile ? 1 : 2}
              content={content}
              indent={{ top: isMobile ? 20 : 30 }}
            />
          </YandexMetrikaGoalSender>
        </TopicContentMaxWidth>
      )}
    </Fragment>
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
  mobileAds: PropTypes.arrayOf(PropTypes.object),
};

export default withBreakpoint(SiteTopicContent);
