import React, { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import GlobalStyles from '../global';
import styles from './index.styl';

const NativeStyles = ({ theme }) => (
  <Fragment>
    <style global jsx>{`
      .adv-listing-spec-rubric
        .ad_native_title
          font bold 20px/1.2 ${theme.fonts.display}

        .adv_info__text
          font 18px/1.2 ${theme.fonts.display}
          color ${theme.colors.primary}
        .adv_info__text
          font 14px/1.2 ${theme.fonts.display}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function listingSpecRubricStyles(Component) {
  return withTheme(props => (
    <div className={cx(styles['adv-listing-spec-rubric'], 'adv-native adv-listing-spec-rubric')}>
      <GlobalStyles {...props} />
      <NativeStyles {...props} />

      <Component {...props} />
    </div>
  ));
}
