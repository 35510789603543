import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withBreakpoint from 'core/hocs/withBreakpoint';

import Link from 'core/components/Link';
import ThinButton from 'site/components/ThinButton';

import styles from './index.styl';

const controls = [];

function Additional({ isMobile }) {
  return (
    <Fragment>
      {controls.map(item => (
        <div key={item.link} className={cx(styles.additional, isMobile && styles.mobile)}>
          <Link
            to={item.link}
            className={styles.link}
            type='secondary'
          >
            <ThinButton
              color={item.color}
              size='small'
              type='primary'
            >
              {item.content}
            </ThinButton>
          </Link>
        </div>
      ))}
    </Fragment>
  );
}

Additional.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Additional);
